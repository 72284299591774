import { GmUploadMaterial } from '@adCommonComponent'; //素材上传
export default {
  components: { GmUploadMaterial },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    currentAddSaleId() {
      return this.$store.state.num.currentAddSaleId;
    },
    userInfo() {
      return this.$store.state.user.userInfo.data;
    },
    saveAdFileParam() {
      return {
        initiatorUid: this.userInfo.id,
        uid: this.userInfo.id,
        orgCode: this.userInfo.orgCode,
        saleId: this.adTypeMsg.saleId || this.currentAddSaleId, //批量编辑 || 创建
        langId: this.adTypeMsg.langId || this.adSeriesInfo?.sale?.langId || 0,
        proId: this.adTypeMsg.productId || this.adSeriesInfo?.sale?.productId || 0,
      };
    },
  },
};
