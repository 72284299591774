import { mediaFileUploadToFb, querySlideshowVideo, newUpload, saveMedia } from '@/api/creatAd.js';
// import GmUploadMaterialMix from './GmUploadMaterial.js';
import { GmUploadMaterial } from '@adCommonComponent';
export default {
  components: { GmUploadMaterial },
  // mixin: [GmUploadMaterialMix],
  computed: {
    currentAddSaleId() {
      return this.$store.state.num.currentAddSaleId;
    },
    userInfo() {
      return this.$store.state.user.userInfo.data;
    },
    saveAdFileParam() {
      return {
        initiatorUid: this.userInfo.id,
        uid: this.userInfo.id,
        orgCode: this.userInfo.orgCode,
        saleId: this.value?.saleId || this.adTypeMsg?.saleId || this.currentCreative?.saleId || this.currentAddSaleId,
        langId:
          this.value?.langId ||
          this.adTypeMsg?.langId ||
          this.adsMsg?.langId ||
          this.currentCreative?.langId ||
          this.adSeriesInfo?.sale?.langId ||
          0, // 编辑视频 || 编辑 ||创建
        proId:
          this.value?.productId ||
          this.adTypeMsg?.productId ||
          this.adsMsg?.productId ||
          this.currentCreative?.productId ||
          this.adSeriesInfo?.sale?.productId ||
          0,
      };
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  methods: {
    // 判断文件格式
    beforeUpload(file, noticy) {
      console.log(file, noticy, this.uploadTypes, 'file, noticy');

      let isJPG = true;
      let isMP4 = true;
      // console.log(file);
      if (this.uploadTypes == 'video') {
        isMP4 = file.type === 'video/mp4';
        console.log(isMP4, 'isMP4');

        // if (file.type === 'image/gif') {
        // this.fileType = 'gif'
        // }
        if (!isMP4) {
          noticy
            ? this.$notify.error({
                title: '错误',
                message: `${file.name} 视频格式错误`,
                duration: 0,
              })
            : this.$message.warning('请上传正确视频格式');
          return false;
        }
        console.log(file.size / 1024 / 1024 > 100, 'file.size / 1024 / 1024 > 100');

        if (file.size / 1024 / 1024 > 100) {
          noticy
            ? this.$notify.error({
                title: '错误',
                message: `${file.name} 文件大于100M`,
                duration: 0,
              })
            : this.$message.warning('上传文件不能大于100M');
          return false;
        }
      }
      if (this.uploadTypes == 'img' || this.uploadTypes == 'banner') {
        // console.log(file.type);
        isJPG =
          file.type == 'image/jpeg' ||
          file.type == 'image/png' ||
          file.type == 'image/jpg' ||
          file.type == 'image/webp' ||
          (this.uploadTypes == 'img' && file.type === 'image/gif');
        if (!isJPG) {
          this.$message.warning('请上传正确图片格式');
          return false;
        }
      }
    },
    // 上传视频到FB
    async uploadVideoFb(mediaId, sourceType) {
      let params = { mediaId, sourceType, accountId: this.$store.getters.accountId };
      // this.$showLoading();
      this.sourceMsg.videoStatus = 'processing';
      try {
        const res = await mediaFileUploadToFb(params);
        if (res.code == 0) {
          this.getVideoUrl(res.data.videoId, res.data.sourceType);
          // this.$set(this.adsList[this.activeIndex], 'uploadNums', 1)
          console.log(res.data, 'res.data');
          return res.data; // 返回响应数据
        } else {
          this.sourceMsg.videoStatus = 'error';
          return null;
        }
      } catch (err) {
        this.sourceMsg.videoStatus = 'error';
        return null;
      } finally {
        this.$hideLoading();
      }
    },
    // 轮训上传进度
    getVideoUrl(id, sourceType) {
      querySlideshowVideo({
        sourceType,
        videoId: id,
        accountId: this.$store.getters.accountId,
      })
        .then((res) => {
          if (res.code == 0) {
            this.sourceMsg.videoStatus = res.data.videoStatus;
            console.log('res.data.videoStatus', this.sourceMsg);

            if (res.data.videoStatus == 'processing') {
              if (this.sourceMsg.cancleVideoUpload || this.resetUploadFlag) return;
              this.timer = setTimeout(() => {
                this.getVideoUrl(id, sourceType), (this.sourceMsg.uploadNums += 1);
              }, 5000);
            } else if (res.data.videoStatus == 'ready') {
              this.sourceMsg.videoStatus = 'ready';
              this.sourceMsg.thumbList = res.data.thumbnails;
              if (this.setThumbList) {
                console.log('getVideoUrl', res.data.thumbnails, id, sourceType);

                this.setThumbList(res.data.thumbnails, sourceType);
              }
              // this.setVideoUrl(res.data.mediaFile, res.data.thumbnails)
              this.$hideLoading();
              // this.$emit('setVideoUrl',res.data.mediaFile);
            } else {
              this.$hideLoading();
              this.$message({
                type: 'warning',
                message: '失败,请重新上传视频',
              });
              // 批量上传视频，不置空
              if (!this.errorNotSetNull) {
                this.sourceMsg = {
                  videoStatus: 'error',
                  url: null,
                  source_id: null,
                };
              } else {
                this.sourceMsg.videoStatus = 'error';
              }
            }
            this.$forceUpdate();
          } else {
            this.$hideLoading();
            this.$message({
              type: 'warning',
              message: '失败,请重新上传视频',
            });
            if (!this.errorNotSetNull) {
              this.sourceMsg = {
                videoStatus: 'error',
                url: null,
                source_id: null,
              };
            } else {
              this.sourceMsg.videoStatus = 'error';
            }
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.getVideoUrl(id, sourceType);
        });
    },
    // // 素材保存接口
    // async saveMedia(data) {
    //   return await saveMedia(data).then((res) => {
    //     return res.data;
    //   });
    // },
    // newUpload(formData, data) {
    //   return newUpload(formData, data)
    //     .then((res) => {
    //       this.$hideLoading();
    //       if (res.code == 0) {
    //         this.$message({
    //           type: 'success',
    //           message: '上传成功',
    //         });
    //         return this.saveMedia(res.data).then((res1) => {
    //           return res1;
    //         });
    //         // return ;
    //       } else {
    //         return { status: 'error' };
    //       }
    //     })
    //     .catch((res) => {
    //       return { status: 'error' };
    //     });
    // },
    // // 最新上传接口
    // async uploadImg(file) {
    //   console.log(file);
    //   let formData = new FormData();
    //   let key = '';
    //   if (this.uploadTypes == 'video') {
    //     key = 'VIDEO';
    //   } else if (this.uploadTypes == 'img') {
    //     key = 'IMAGE';
    //   }
    //   let fileType = file.file.type;
    //   let isJPG =
    //     fileType == 'image/jpeg' || fileType == 'image/png' || fileType == 'image/jpg' || fileType == 'image/webp';
    //   let isMP4 = fileType === 'video/mp4' || fileType === 'image/gif';
    //   // console.log(!(((key == 'VIDEO') && (isMP4)) || ((key = 'IMAGE') && (isJPG))));
    //   if (!((key == 'VIDEO' && isMP4) || ((key = 'IMAGE') && isJPG))) {
    //     this.$message({
    //       type: 'warning',
    //       message: '素材类型不一致，请重新选择素材上传',
    //     });
    //     return false;
    //   }
    //   formData.append('file', file.file);
    //   formData.append('type', key);
    //   this.$showLoading();
    //   return this.newUpload(formData, { type: key });
    // },
  },
};
